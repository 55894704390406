<template>
  <span>
      <h1 class="headline blue--text text-center mt-4 mb-3">Tuntipalkkajako</h1>
      <v-divider></v-divider>
      <v-card v-if="resource">
          <v-card-text>
              Jako valitsemallasi ajanjaksolla:
              <br/>

          </v-card-text>
          <v-card-actions class="pl-16 pr-16">
              AIKUSVIIHDE: {{ resource.aikuisviihde_share }}%
              <v-spacer></v-spacer>
              ASIANTUNTIJA: {{ resource.asiantuntija_share }}%
          </v-card-actions>
      </v-card>


    <v-select
            v-model="salaryPeriods"
            :items="periods"
            :return-object="false"
            item-value="id"
            label="Valitse aikajakso(t)"
            multiple
    >
        <template #item="{ item }">
          {{ item.start }} - {{ item.end }}
        </template>
        <template #selection="{ item }">
          {{ item.start }} - {{ item.end }}
        </template>
    </v-select>
  </span>
</template>

<script>
import moment from "moment";
export default {
    name: "SalaryReportingDivision",
    data: function () {
        return {
            startDate: null,
            salaryPeriods: [],
            resource: null,
            periods: [],
        }
    },
    watch: {
        salaryPeriods(){
            console.log(this.salaryPeriods.length)
            if(this.salaryPeriods.length > 0) this.getResource()
        }
    },
    methods:{
        async getResources(){
            await this.axios.get('reports/salary_periods', {
                params:{
                    skip: 0,
                    take: 24,
                }
            }).then((resources) => {
                this.periods = resources.data
            }).catch ((error) => {
                console.error(error);
            });
        },

        async getResource(){
            this.resourceLoading = true
            this.copied = false
            this.resource = []
            await this.axios.post('reports/salary_share_report', {
                  salary_periods: this.salaryPeriods
            }).then((resources) => {
                this.resource = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.resourceLoading = false
        },
    },
    mounted(){
        const prevMonth = moment().subtract(1, 'months');
        this.startDate = prevMonth.startOf('month').format('DD.MM.YY');
        this.endDate = prevMonth.endOf('month').format('DD.MM.YY');
        ///api/reports/agents/prepaid_report?from=01.05.23&to=31.05.23&include_test=0
        this.getResources()
        //this.getResource()
    }

}
</script>

<style scoped>

</style>